/* Sidebar.css */
.sidebar {
  position: fixed;
  top: 0;
  left: -300px; /* Adjust width to match the sidebar's width to ensure it's completely hidden */
  width: 300px;
  height: 100%;
  background: #f4f4f4;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5); /* Optional for shadow effect */
  transition: left 0.3s ease; /* Smooth transition for sidebar */
  z-index: 1000; /* Make sure it's above other content */
  }
  
  .sidebar.visible {
    left: 0; /* Show sidebar */
  }
  
  .close-btn {
    position: absolute;
    top: 0; /* Position the button at the top */
    right: 0; /* Position the button to the right */
    padding: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem; /* Adjust the size as necessary */
    cursor: pointer;
  }
  
  
  .color-option {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 10px;
    border: none;
    cursor: pointer;
  }
  
  .close-btn {
    color:black;
  }

  .button {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    border-radius: 50%; /* Make it circular */
    border: 1px solid #555555; /* Ensure a border exists for hover states */
  }

  .button:disabled {
    opacity: 0.35;
    pointer-events: none;
  }
  /* Add more styles for .size-options and .sound-option as needed */
  