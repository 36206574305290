@tailwind base;
@tailwind components;
@tailwind utilities;

/* General body styling */
body {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
}

/* Pause button for sessions */
.pause-button {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  z-index: 10;
}

/* Hamburger menu styling */
.hamburger-menu {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  /* Ensure high enough for interaction */
  cursor: pointer;
}

/* Header styling */
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f4f4;
  padding: 1rem;
}

/* Brand logo/link styling */
.app-brand {
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5em;
}

.nav-toggle {
  display: none;
  /* Hidden on desktop */
  position: relative;
  width: 40px;
  height: 40px;
  /* Adjust height to better fit the icon */
  cursor: pointer;
}

/* This color can be set directly in the icon using Tailwind */
.nav-toggle .icon {
  height: 3px;
  width: 100%;
  border-radius: 10px;
  background-color: transparent;
  /* Will be controlled by Tailwind with text-accent */
}

/* Navigation styling */
.app-nav {
  display: flex;
  /* Horizontally align navigation links */
}

.app-nav ul {
  padding: 1rem;
  /* Increase padding for more space */
  font-size: 1.2rem;
  /* Increase font size */
}

.app-nav ul li {
  margin-right: 20px;
}

.app-nav ul li a {
  text-decoration: none;
  color: #333;
  padding: 0.5em;
}

/* Remove margin for last item in the nav */
.app-nav ul li:last-child {
  margin-right: 0;
}

/* Profile dropdown styling */
.profile-dropdown {
  position: relative;
  display: inline-block;
  /* Ensure inline alignment with other menu items */
}

/* Profile button styling */
.profile-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
}

/* Profile dropdown styling */
.profile-dropdown {
  position: relative;
  display: inline-block;
}

/* Hide the dropdown menu by default */
.dropdown-menu {
  position: absolute;
  top: 100%;
  /* Position it below the button */
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: none;
  /* Hidden by default */
  min-width: 150px;
  z-index: 10;
  flex-direction: column;
  padding: 0;
}

/* Show the dropdown when hovering over the profile dropdown */
.profile-dropdown:hover .dropdown-menu,
.profile-button:hover+.dropdown-menu {
  display: block;
  /* Show on hover */
}

/* Each item in the dropdown */
.dropdown-menu li {
  margin: 0;
  padding: 10px;
  display: block;
  /* Stack items vertically */
  white-space: nowrap;
}

/* Add hover effect for dropdown items */
.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

/* Full width styling for logout button */
.logout-button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  text-align: left;
  padding: 10px;
  display: block;
  /* Make button take full width */
}

/* Hover effect for logout button */
.logout-button:hover {
  background-color: #f0f0f0;
}

/* Slider Label Touchiness */
.slider-label {
  min-width: 1.25em;
  justify-content: center;
  display: flex;
}