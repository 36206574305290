.guided-session-page {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers children vertically */
    align-items: center; /* Centers children horizontally */
    height: 100vh; /* At least the height of the viewport */
    position: relative; /* If it's necessary for other absolute positioned children */
  }
  

.guided-session-page.mood-active .step-modal {
    display: none; /* Hide the step modal when mood modal is active */
  }
  
  .step-modal {
    
    z-index: 2; /* Ensure this is lower than the z-index of the mood tracker */
  }