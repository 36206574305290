@keyframes moveDot {
  0%, 100% {
    transform: translateX(0); 
  }
  50% {
    transform: translateX(calc(100vw - 100%)); 
  }
}

.dot-movement-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;  
  align-items: center;
  justify-content: flex-start; 
  overflow: hidden;
}

.dot {
  border-radius: 50%;
  position: absolute;
  animation: moveDot linear infinite;
  width: 20px;  
  height: 20px;
}
  