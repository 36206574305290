.home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
    padding: 1rem;
  }
  
  .brand-home {
    text-decoration: none;
    font-weight: bold;
    font-size: 1.5em;
  }
  
  .nav-toggle {
    display: none;
    position: relative;
    width: 30px;
    height: 20px;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .nav-toggle .hamburger,
  .nav-toggle .hamburger::before,
  .nav-toggle .hamburger::after {
    content: '';
    background-color: #333;
    height: 3px;
    width: 100%;
    border-radius: 10px;
  }
  
  .nav-toggle .hamburger::before,
  .nav-toggle .hamburger::after {
    position: absolute;
    left: 0;
  }
  
  .nav-toggle .hamburger::before { top: -7px; }
  .nav-toggle .hamburger::after { top: 7px; }
  
  .nav {
    display: flex;
  }
  
  .nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav ul li:not(:last-child) {
    margin-right: 20px;
  }
  
  .nav ul li a {
    text-decoration: none;
    color: #333;
    padding: 0.5em;
  }
  
  /* Responsive section - Tablet and mobile */
  @media (max-width: 768px) {
    .nav-toggle {
      display: flex;
    }
    
    .nav {
      display: none;
      flex-direction: column;
      align-items: flex-start; /* Align to the start, under the brand name */
      padding: 1rem;
      background: #f4f4f4;
      width: 100%; /* Full-width dropdown */
    }
    
    .show-nav {
      display: flex;
    }
  }

  .subscribe-button {
    background-color: #f67e7d;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .subscribe-button:hover {
    background-color: #ffb997;
  }
  